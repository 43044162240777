export const API_VERSION = "v1.0";
export const SOCKET_URL = "ws://localhost:5000/messages";
export const COM_PORT = "COM_PORT";
export const IP_ADDRESS = "IP_ADDRESS";
export const PORT = "PORT";

export const ARENA_TOKEN = ``;
export const SUPER_ADMIN_TOKEN = ``;
export const IMACULIX_TOKEN = ``;
export const CINEMAAKIL_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImI1M2YxZGZlLTllMzEtNDczYi05ODJlLTQ0YWRiNTY4YmMxNSIsImlhdCI6MTYzNzc2MzA5MSwiZXhwIjoxNjQwMzU1MDkxfQ.kaa8bwT5nAAK8cmVN6rLIym-LpmQC0evXrbnAyAfD20`;

export const TOKEN = CINEMAAKIL_TOKEN;

const HOST_URL = `kiosk-cinemaakil.brij.tech`;

const LIVEBASEURL = `https://api-cinemaakil.brij.tech`;
const DEVBASEURL = ``;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;

export const BASEURL =
  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;

//  mode should be an integer
// 1- only ticket
// 2- only fnb
// 3 - both

export const mode = 3;
